import * as React from 'react'
import CartContext, { ICartItem } from './components/shop/CartContext'

export default function WrapRootElement({ children }: any) {
  const [cart, setCart] = React.useState<ICartItem[]>(function() {
    const c = localStorage.getItem('cart')
    if (!c) return []
    return JSON.parse(c)
  })
  const cartContext = {
    cart,
    addToCart: (slug: string, amount: number) => {
      let found = false
      const newCart = cart.map(i => {
        if (i.slug === slug) {
          found = true
          return {
            ...i,
            amount: i.amount + amount
          }
        } else {
          return i
        }
      })
      if (!found) {
        newCart.push({
          slug,
          amount
        })
      }

      setCart(newCart)
      localStorage.setItem('cart', JSON.stringify(newCart))
    },
    updateCart: (slug: string, amount: number) => {
      const newCart = cart.map(i => {
        if (i.slug === slug) {
          return {
            ...i,
            amount: amount
          }
        } else {
          return i
        }
      })
      setCart(newCart)
      localStorage.setItem('cart', JSON.stringify(newCart))
    },
    removeFromCart: (slug: string) => {
      const newCart = cart.filter(i => i.slug !== slug)
      setCart(newCart)
      localStorage.setItem('cart', JSON.stringify(newCart))
    },
    clearCart: () => {
      const newCart: ICartItem[] = []
      setCart(newCart)
      localStorage.setItem('cart', JSON.stringify(newCart))
    }
  }
  return <CartContext.Provider value={cartContext}>{children}</CartContext.Provider>
}
