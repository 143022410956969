// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-product-tsx": () => import("./../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */),
  "component---src-templates-category-tsx": () => import("./../src/templates/category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-producer-tsx": () => import("./../src/templates/producer.tsx" /* webpackChunkName: "component---src-templates-producer-tsx" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-infos-tsx": () => import("./../src/pages/infos.tsx" /* webpackChunkName: "component---src-pages-infos-tsx" */),
  "component---src-pages-kasse-tsx": () => import("./../src/pages/kasse.tsx" /* webpackChunkName: "component---src-pages-kasse-tsx" */),
  "component---src-pages-shop-tsx": () => import("./../src/pages/shop.tsx" /* webpackChunkName: "component---src-pages-shop-tsx" */),
  "component---src-pages-shop-payment-cancel-tsx": () => import("./../src/pages/shop/payment/cancel.tsx" /* webpackChunkName: "component---src-pages-shop-payment-cancel-tsx" */),
  "component---src-pages-shop-payment-success-tsx": () => import("./../src/pages/shop/payment/success.tsx" /* webpackChunkName: "component---src-pages-shop-payment-success-tsx" */),
  "component---src-pages-warenkorb-tsx": () => import("./../src/pages/warenkorb.tsx" /* webpackChunkName: "component---src-pages-warenkorb-tsx" */)
}

