import * as React from 'react'

export interface ICartItem {
  slug: string
  amount: number
}
export interface ICartContext {
  cart: ICartItem[]
  addToCart: (slug: string, amount: number) => void
  updateCart: (slug: string, amount: number) => void
  removeFromCart: (slug: string) => void
  clearCart: () => void
}
const CartContext = React.createContext<ICartContext>({
  cart: [],
  addToCart: (slug: string, amount: number) => {},
  updateCart: (slug: string, amount: number) => {},
  removeFromCart: (slug: string) => {},
  clearCart: () => {}
})

export default CartContext
